import { render, staticRenderFns } from "./applicant-details-dialog.vue?vue&type=template&id=cb7d508c&scoped=true&"
import script from "./applicant-details-dialog.vue?vue&type=script&lang=js&"
export * from "./applicant-details-dialog.vue?vue&type=script&lang=js&"
import style0 from "./applicant-details-dialog.vue?vue&type=style&index=0&id=cb7d508c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb7d508c",
  null
  
)

export default component.exports