<template>
  <div>
    <article>
      <section>
        <div class="container">
          <div class="students_dialogs text-left">
            <el-dialog title="Application Details"
              :visible="isVisible"
              @open="getApplicantDetails()"
              :destroy-on-close="true"
              :before-close="handleClose">
              <div v-loading="submitting">

                <div class="container-fluid">
                  <div class="row">
                    <div class="container-fluid text-left">

                      <!-- passport -->
                      <div class="col-auto pl-0 mb-3">
                        <div class="card-0">
                          <div class="row align-items-end">
                            <div class="col col-auto text-center">
                              <div class="
                                id-photo
                                d-flex
                                justify-content-center
                                align-items-center
                              ">
                                <img v-if="circularOfficialPhotoURL"
                                  :src="circularOfficialPhotoURL" />
                                <i v-else
                                  class="el-icon-user-solid"></i>
                              </div>
                              <small class="label-text my-2">Identity Card Photo</small>
                            </div>
                            <div class="col col-auto text-center">
                              <div class="
                                academic-photo
                                d-flex
                                justify-content-center
                                align-items-center
                              ">
                                <img v-if="squareOfficialPhotoURL"
                                  :src="squareOfficialPhotoURL" />
                                <i v-else
                                  class="el-icon-user-solid"></i>
                              </div>
                              <small class="label-text my-2">Academic Photo</small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- payment link -->
                      <div class="row mt-3">
                        <div class="col-12">
                          <p class="">
                            <span style="font-weight: 700">Application Fee Payment Link:</span>
                            {{ paymentLink || "N/A" }}
                          </p>
                        </div>
                        <div class="col-12">

                          <span style="font-weight: 600"
                            class="links pl-4 float-right"
                            @click="sendViaEmail">Send via email </span>
                          <span style="font-weight: 600"
                            class="links float-right"
                            @click="copyPaymentLinkToClipboard">Copy Link</span>
                        </div>

                      </div>

                      <!-- Basic information    -->
                      <section class="row">
                        <div class="col-12 mt-3">
                          <h4>Basic Information</h4>
                          <hr class="my-2" />
                          <div class="row mt-3">
                            <div class="col-12 col-lg-12">
                              <p>
                                <span style="font-weight: 700">Name:</span>
                                {{ online_application.title }}
                                {{ online_application.first_name }}
                                {{ online_application.last_name }}
                              </p>
                              <p class="">
                                <span style="font-weight: 700">Gender:</span>
                                {{ online_application.gender || "N/A" }}
                              </p>
                              <p class="">
                                <span style="font-weight: 700">Email:</span>
                                {{ online_application.email || "N/A" }}
                              </p>
                              <p class="">
                                <span style="font-weight: 700">Phone Number:</span>
                                {{ online_application.phone_number || "N/A" }}
                              </p>
                              <p>
                                <span style="font-weight: 700">Date of Birth:</span>
                                {{ formattedDate(online_application.dob) || "N/A" }}
                              </p>
                              <p v-if="online_application.course">
                                <span style="font-weight: 700">Course Code:</span>
                                {{ online_application.course.title }}
                                ({{ online_application.course.course_code }})
                              </p>
                              <p>
                                <span style="font-weight: 700">Nationality:</span>
                                {{ online_application.nationality || "N/A" }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </section>

                      <!-- Bio data -->
                      <section class="row mt-2">
                        <div class="col-12">
                          <h4>Bio Data</h4>
                          <hr />
                        </div>
                        <div class="col-12">
                          <p>
                            <span style="font-weight: 700">Religion:</span>
                            {{ online_application.religion || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Marital Status:</span>
                            {{ online_application.marital_status || "N/A" }}
                          </p>

                          <p v-show="online_application.marital_status == 'Married'">
                            <span style="font-weight: 700">Spouse Occupation:</span>
                            {{ online_application.spouse_occupation || "N/A" }}
                          </p>
                          <p v-show="online_application.marital_status == 'Married'">
                            <span style="font-weight: 700">Spouse Name:</span>
                            {{ online_application.spouse_name || "N/A" }}
                          </p>
                          <p v-show="online_application.marital_status == 'Married'">
                            <span style="font-weight: 700">Spouse contacts:</span>
                            {{ online_application.spouse_contacts || "N/A" }}
                          </p>
                          <p v-show="online_application.marital_status == 'Married'">
                            <span style="font-weight: 700">Spouse disabilities:</span>
                            {{ online_application.disabilities || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Physical Address:</span>
                            {{ online_application.physical_address || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Country of Residence:</span>
                            {{ online_application.residence_country || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Home district:</span>
                            {{ online_application.home_district || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Occupation:</span>
                            {{ online_application.occupation || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Next of Kin</span>
                            {{ online_application.next_of_kin || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Next of kin Relationship</span>
                            {{ online_application.kin_relationship || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Next of kin Contacts</span>
                            {{ online_application.kin_contacts || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Sponsor Name</span>
                            {{ online_application.sponsor_name || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Sponsor Contact</span>
                            {{ online_application.sponsor_contact || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Recruiter Name</span>
                            {{ online_application.recruiter_name || "N/A" }}
                          </p>
                          <p>
                            <span style="font-weight: 700">Recruiter Contact</span>
                            {{ online_application.recruiter_contact || "N/A" }}
                          </p>
                        </div>
                      </section>

                      <!-- Bio data -->
                      <section class="row mt-2">
                        <div class="col-12">
                          <h4>Education</h4>
                          <hr />
                        </div>
                        <div class="col-12">
                          <p>
                            <span style="font-weight: 700">Student Type</span>
                            {{
                              online_application.is_local_student === true
                              ? "Local student"
                              : "International student"
                            }}
                          </p>
                          <div v-if="online_application.is_local_student == true">
                            <p>
                              <span style="font-weight: 700">O' Level School:</span>
                              {{ online_application.o_level_school_name || "N/A" }}
                            </p>
                            <p>
                              <span style="font-weight: 700">O' Level Year:</span>
                              {{ online_application.o_level_school_year || "N/A" }}
                            </p>
                            <p>
                              <span style="font-weight: 700">O' Level Index No:</span>
                              {{ online_application.o_level_school_index_no || "N/A" }}
                            </p>

                            <p>
                              <span style="font-weight: 700">A' Level School:</span>
                              {{ online_application.a_level_school_name || "N/A" }}
                            </p>
                            <p>
                              <span style="font-weight: 700">A' Level Year:</span>
                              {{ online_application.a_level_school_year || "N/A" }}
                            </p>
                            <p>
                              <span style="font-weight: 700">A' Level Index No:</span>
                              {{ online_application.a_level_school_index_no || "N/A" }}
                            </p>
                          </div>
                        </div>
                      </section>

                      <!-- Documents -->
                      <section class="row mt-2">
                        <div class="col-12">
                          <h4>Documents added</h4>
                          <hr />
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12">
                              <div class="row justify-content-start">
                                <div class="col col-auto"
                                  v-for="(doc, index) in bioDocuments"
                                  :key="index">
                                  <div class="document-card"
                                    @click="openDocumentInPopupWindow(doc)">
                                    <div class="list-icon">
                                      <i class="el-icon-document"></i>
                                    </div>
                                    <div class="list-content doc-title-trim">
                                      {{ doc.title }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                    </div>
                  </div>
                </div>

              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import print from "vue-print-nb";
import { format, parseISO } from "date-fns"
export default {
  directives: {
    print,
  },
  emits: ["re-fresh"],
  data() {
    return {
      studentDetailsModal: false,
      loadingError: false,
      submitting: false,
      online_application: {},
      bioDocuments: [],
      officialPhotos: [],
      paymentLink: "",
    };
  },

  props: {
    websiteOnlineApplicationId: {
      type: String,
      required: true,
    },

    isVisible: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    circularOfficialPhotoURL() {
      const circularPhoto = this.officialPhotos.find(
        (photo) => photo.is_circular_official_picture === true
      );
      return circularPhoto ? circularPhoto.path_url : "";
    },

    squareOfficialPhotoURL() {
      const squarePhoto = this.officialPhotos.find(
        (photo) => photo.is_square_official_picture === true
      );
      return squarePhoto ? squarePhoto.path_url : "";
    },
  },

  methods: {
    formattedDate(dateString) {
      if (!dateString) return "";
      return format(parseISO(dateString), "do MMMM yyyy");
    },
    async showModal() {
      try {
        this.submitting = true;
        await this.getApplicantDetails();
      } catch (error) {
        this.submitting = true;
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occured, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to load Student",
          "An unexpected Error occured, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },

    handleClose() {
      this.online_application = {};
      this.bioDocuments = [];
      this.officialPhotos = [];
      this.$emit("closeStudentInfoDialog");
    },

    async getAllStudents() {
      this.$emit("re-fresh");
    },

    async getApplicantDetails() {
      // eslint-disable-next-line no-useless-catch
      try {
        this.submitting = true;
        let request = await this.$http.get(
          `website/online-web-applications/fetch-one/${this.websiteOnlineApplicationId}`
        );
        if (
          request.data.success &&
          request.data.message == "ONLINE APPLICATION FETCHED SUCCESSFULLY"
        ) {
          this.paymentLink = request.data.payment_link
          this.online_application = request.data.online_application;
          this.bioDocuments = this.online_application.bio_documents.filter(
            (doc) => {
              if (
                doc.is_circular_official_picture == false &&
                doc.is_square_official_picture == false
              ) {
                return true;
              } else {
                false;
              }
            }
          );

          this.officialPhotos = this.online_application.bio_documents.flatMap(
            (doc) => {
              //If it's circular or square photo, return it
              if (
                doc.is_circular_official_picture ||
                doc.is_square_official_picture
              ) {
                return [
                  {
                    path_url: doc.file_path_url,
                    ...doc,
                  },
                ];
              } else {
                return [];
              }
            }
          );
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (error.response.data.message === "API MESSAGE HERE") {
            return;
          }
        } else {
          this.showFailedMessage(
            "Operation failed",
            "Unable to fetch student data, Please try again"
          );
        }
      } finally {
        this.submitting = false;
      }
    },

    openDocumentInPopupWindow(doc) {
      window.open(doc.file_path_url, doc.title);
    },

    async deleteStudent() {
      // return; // eslint-disable-next-line no-unreachable
      await this.$confirm(
        "This will permanently delete this student. Do you want to continue?",
        "Confirm delete",
        {
          // eslint-disable-line no-unreachable
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.submitting = true;
        let request = await this.$http.delete(
          `students/delete-one/${this.websiteOnlineApplicationId}`
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT_DELETED_SUCCESSFULLY"
        ) {
          this.showSuccessMessage("Success", "Student deleted successfully!");
          this.studentDetailsModal = false;
          this.submitting = false;
        } else throw "UNEXPECTED ERROR OCCURRED";
      } catch (error) {
        if (error.message == "Network Error") {
          this.submitting = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occured, please try again."
          );
        }

        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (error.response.data.message === "API MESSAGE HERE") {
            return;
          }
        } else {
          this.showFailedMessage(
            "Unable to delete Student",
            "An unexpected Error occured, please try again"
          );
        }
      } finally {
        this.submitting = false;
      }
    },

    onOpenDetailedInfoDialog(websiteOnlineApplicationId) {
      this.websiteOnlineApplicationId = websiteOnlineApplicationId;
      this.showDetailedInfoDialog = true;
    },

    onCloseDetailedInfoDialog() {
      this.showDetailedInfoDialog = false;
      this.handleClose();
    },

    copyPaymentLinkToClipboard() {

      if (this.paymentLink) {
        navigator.clipboard.writeText(this.paymentLink);
        this.showSuccessMessage("Success", "Payment Link copied successful");
      }
    },

    async sendViaEmail() {
      let request = await this.httpRequest({
        method: "POST",
        url: `website/online-web-applications/send-payment-link/${this.websiteOnlineApplicationId}`,
        loadingPropertyName: "submitting",
        errorLoadingPropertyName: null,
        showSuccessMessage: true,
      });

      // if (
      //   request &&
      //   request.success &&
      //   request.message == "Email sent successfully"
      // ) {
      //   this.getApplicantDetails()
      // }
      console.log(request);
    },
  },
};
</script>

<style scoped>
.document-card {
  width: 150px;
  height: 150px;
  border: dotted 1px grey;
  color: var(--el-app-primary);
  font-size: 0.92em;
  background: transparent;
  transition: 0.3s ease;
  border-radius: 10px;
  justify-content: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  border: solid 0.7px #558899;
  border-spacing: 4px;
  margin-bottom: 10px;
}

.academic-photo {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  border: dotted var(--el-app-primary) 1px;
  background-color: #f5f5f5;
}

.id-photo {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: dotted var(--el-app-primary) 1px;
  background-color: #f5f5f5;
}

.academic-photo img,
.id-photo img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.academic-photo img {
  border-radius: 10px;
}

.id-photo,
.academic-photo {
  font-size: 5rem !important;
  color: #999;
}

.card-0 {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: #fefefe;
  padding: 20px;
  border: solid #f5f5f5 1px;
}

.links {
  cursor: pointer;
}

.links:hover {
  color: var(--el-app-primary);
  text-decoration: underline;
}
</style>
