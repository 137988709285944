<template>
  <div class="px-0 container-fluid h-100" style="text-align: left">
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100">
            <div class="col-12 text-left">
              <BreadCrumbComponent :pageTitles="['Online Applications']" />
              <div
                class="mt-3"
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>Online Applications</strong><br />
              </div>
            </div>
          </div>
          <div class="search_add_section">
            <div class="" style="display: flex">
              <input
                type="text"
                @input="onSearch"
                v-model.trim="searchByValue"
                placeholder="Search by name or nationality"
                class="search_by_input"
                spellcheck="off"
              />

              <div style="margin-left: 20px">
                <el-select
                  v-model="webAdminStatusFilter"
                  @handleOptionClick="onSearch"
                  placeholder="Select"
                  class="d-block"
                  style="height: 40px !important"
                >
                  <el-option
                    v-for="item in webAdminStatusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>

              <div style="margin-left: 20px">
                <el-select
                  v-model="selectedPayStatusFilter"
                  @handleOptionClick="handleOptionClick"
                  placeholder="Select"
                  class="d-block"
                  style="height: 40px !important"
                >
                  <el-option
                    v-for="item in payStatusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>

              <div style="margin-left: 20px">
                <!-- <el-select v-model="selectedFilter"
                  @handleOptionClick="handleOptionClick"
                  placeholder="Select"
                  class="d-block"
                  style="height: 40px !important">
                  <el-option v-for="item in filterOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select> -->

                <el-dropdown>
                  <el-button
                    type="primary"
                    style="
                      background-color: var(--el-app-primary) !important;
                      color: white;
                      width: auto;
                      height: 40px;
                    "
                  >
                    Actions <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu
                    trigger="click"
                    class="project-dropdown"
                    slot="dropdown"
                  >
                    <el-dropdown-item
                      v-for="option in actionOptions"
                      :key="option"
                      @click.native="onActionClick(option)"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 170px;
                      "
                    >
                      {{ option }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
          <hr />
          <div
            v-loading="submitting || (loading && applicants.length > 0)"
            class="table-responsive"
          >
            <data-tables-server
              :data="applicants"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [10, 25, 50] }"
              type="expand"
              :total="totalApplicants"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              @query-change="onTableChange"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getAllApplications"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Applications Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Applications</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{
                    (currentPage - 1) * pageSize + scope.$index + 1
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Applicant Info"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.title }} {{ scope.row.first_name }}
                    {{ scope.row.last_name }}
                    <span
                      v-if="scope.row.is_rejected"
                      class="badge badge-light badge-danger z-depth-0 ml-2 p-1"
                      style="
                        border-radius: 5px;
                        font-size: 0.7em;
                        font-weight: 500;
                      "
                    >
                      REJECTED
                    </span>
                    <span
                      v-else-if="!scope.row.is_confirmed"
                      class="badge badge-light badge-info z-depth-0 ml-2 p-1"
                      style="
                        border-radius: 5px;
                        font-size: 0.7em;
                        font-weight: 500;
                      "
                    >
                      NOT CONFIRMED
                    </span>
                    <span
                      v-if="scope.row.student"
                      class="badge badge-light badge-info z-depth-0 ml-2 p-1"
                      style="
                        border-radius: 5px;
                        font-size: 0.7em;
                        font-weight: 500;
                      "
                    >
                      {{ formattedApplicantStatus(scope.row.student.state) }}
                    </span>
                  </div>
                  <div>
                    <strong>Nationality: </strong>
                    {{ scope.row.nationality }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Phone Number" width="150px">
                <template slot-scope="scope">
                  {{ scope.row.phone_number }}
                </template>
              </el-table-column>

              <el-table-column label="Course Code" width="120px">
                <template slot-scope="scope">
                  <span>{{ scope.row.course.course_code }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Application Fee"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="150px"
              >
                <template slot-scope="scope">
                  <div>
                    <span
                      v-if="scope.row.has_paid_application_fee"
                      style="color: green; font-weight: 400"
                      ><i class="el-icon-circle-check"></i>
                      Paid
                    </span>
                    <span v-else style="color: orange; font-weight: 400"
                      ><i class="el-icon-warning-outline"></i>
                      Not Paid
                    </span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Application date" width="180px">
                <template slot-scope="scope">
                  {{ formattedDate(scope.row.created_at) }}
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                label-class-name="text-center"
                class-name="text-center"
                style="display: none"
                width="80px"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; justify-content: center">
                    <el-dropdown
                      trigger="click"
                      @command="handleActionsSelection"
                    >
                      <span class="el-dropdown-link">
                        <el-button
                          type="primary"
                          icon="el-icon-more"
                          circle
                          style="margin-right: 10px"
                        >
                        </el-button>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item disabled
                          >Application Details</el-dropdown-item
                        >
                        <el-dropdown-item
                          divided
                          :command="`student-information#${scope.row.website_online_application_id}`"
                        >
                          <span class="drop-down-items">
                            <i class="el-icon-edit"></i> Application Information
                          </span>
                        </el-dropdown-item>
                        <el-dropdown-item
                          :command="`toggle-read-status#${scope.row.website_online_application_id}`"
                        >
                          <span class="drop-down-items">
                            <i class="el-icon-circle-check"></i>
                            {{
                              scope.row.is_read
                                ? "Mark as Unread"
                                : "Mark as Read"
                            }}
                          </span>
                        </el-dropdown-item>
                        <!-- <el-dropdown-item :command="`edit-online-application#${scope.row.website_online_application_id}`">
                          <span class="drop-down-items">
                            <i class="el-icon-edit"></i> Edit Online Application
                          </span>
                        </el-dropdown-item> -->
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-row>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>
        </div>
      </section>
    </article>

    <section>
      <article>
        <ApplicantDetailsDialog
          @re-fresh="getAllApplications"
          :websiteOnlineApplicationId="websiteOnlineApplicationId"
          :isVisible="showStudentInfoDialog"
          @closeStudentInfoDialog="closeStudentInfoDialog"
        />

        <ChooseDateRangeDialog
          :dialogVisible="chooseLectureRangeDialogVisible"
          @on-close="chooseLectureRangeDialogVisible = false"
          @on-range-chosen="onRangeChosen"
        />
      </article>
    </section>
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component.vue";
import debounce from "debounce";
import ApplicantDetailsDialog from "../components/dialogs/applicant-details-dialog.vue";
import ChooseDateRangeDialog from "../components/dialogs/choose-date-range-dialog.vue";
import { format, parseISO } from "date-fns";

export default {
  components: {
    ScaleOut,
    BreadCrumbComponent,
    ApplicantDetailsDialog,
    ChooseDateRangeDialog,
  },

  data() {
    return {
      showStudentInfoDialog: false,
      isExportActiveStudentsDialogOpen: false,
      chooseLectureRangeDialogVisible: false,
      websiteOnlineApplicationId: "",
      filterDateStart: "",
      filterDateEnd: "",
      totalApplicants: 0,
      currentPage: 0,
      pageSize: 10,

      loading: true,
      loadingError: false,
      submitting: false,
      applicants: [],
      tableProps: {
        border: true,
        stripe: true,
      },
      nationalitySearch: "",
      searchByValue: "",
      selectedFilter: "Show All",
      selectedPayStatusFilter: "",
      payStatusOptions: [
        {
          label: "Show All",
          value: "",
        },
        {
          label: "Show Only Paid",
          value: "ONLY_PAID",
        },
        {
          label: "Show Only Not Paid",
          value: "ONLY_NOT_PAID",
        },
      ],
      webAdminStatusFilter: "UNREAD",
      actionOptions: ["Select a date range", "Export to Excel"],
      webAdminStatusOptions: [
        {
          label: "Show All",
          value: "",
        },
        {
          label: "Only Unread",
          value: "UNREAD",
        },
        {
          label: "Only Read",
          value: "READ",
        },
        {
          label: "Only Confirmed",
          value: "CONFIRMED",
        },
        {
          label: "Only Pending",
          value: "PENDING",
        },
        {
          label: "Only Rejected",
          value: "REJECTED",
        },
      ],
    };
  },
  watch: {
    selectedPayStatusFilter() {
      this.onSearch();
    },

    selectedFilter() {
      //If the selected filter is for anything other than choose custom day, then reload the table info
      if (
        this.selectedFilter == "Show All" ||
        this.selectedFilter == "Show Only Today"
      ) {
        this.filterDateStart = "";
        this.filterDateEnd = "";
        this.onSearch();
      }
    },
  },

  computed: {
    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },
  },

  mounted() {
    //this.getAllApplications();
  },
  methods: {
    onActionClick(option) {
      if (option === "Select a date range") {
        this.chooseLectureRangeDialogVisible = true;
      } else if (option === "Export to Excel") {
        this.getAllApplications(true);
      }
    },

    formattedApplicantStatus(status) {
      if (status === "AR_VERIFIED") {
        return "VERIFIED";
      } else if (status == "ADMISSION_REJECTED") {
        return "LATER REJECTED";
      } else if (status == "SCHOOLING" || status === "GRADUATED") {
        return "ADMITTED";
      }
      //Since pending is the original status, then we can return it as Pending instead
      else if (status == "ADMITTED") {
        return "PENDING VERIFICATION";
      } else {
        return status;
      }
    },

    onRangeChosen(startAndEndTime) {
      this.chooseLectureRangeDialogVisible = false;
      this.filterDateStart = startAndEndTime[0];
      this.filterDateEnd = startAndEndTime[1];
      this.getAllApplications();
    },
    onSearch: debounce(function () {
      this.getAllApplications();
    }, 500),

    formattedDate(dateIso) {
      return format(parseISO(dateIso), "do MMM yyyy hh:mma");
    },

    async onTableChange(queryInfo) {
      if (queryInfo) {
        if (
          queryInfo.type == "page" ||
          queryInfo.type == "size" /* ||
            queryInfo.type == "filter" */
        ) {
          this.currentPage = queryInfo.page;
          this.pageSize = queryInfo.pageSize;
          this.getAllApplications();
        }
      }
    },

    handleMoreOptionsSelection(command) {
      if (command === "export-all-students-as-excel") {
        //handle export all students as excel
        this.exportStudents();
      }
      if (command === "export-active-students-as-excel") {
        //handle export active students as excel
        this.isExportActiveStudentsDialogOpen = true;
      }
    },

    handleExportActiveStudentsDialogClose() {
      this.isExportActiveStudentsDialogOpen = false;
    },

    async getAllApplications(exportToExcel = false) {
      try {
        this.loading = true;
        this.loadingError = false;

        let showOnly = "";
        let onlyToday = "";
        if (this.selectedFilter === "Show All") {
          showOnly = "";
        } else if (this.selectedFilter == "Show Only Today") {
          onlyToday = true;
        }

        const excelQueryString = exportToExcel ? "&exportToExcel=true" : "";
        let request = await this.$http.get(
          `website/online-web-applications/fetch-many?fromWebsiteAdmin=true&page=${this.currentPage}&pageSize=${this.pageSize}&searchName=${this.searchByValue}&showOnly=${showOnly}&onlyToday=${onlyToday}&startDate=${this.filterDateStart}&endDate=${this.filterDateEnd}&filter=${this.selectedPayStatusFilter}&webAdminStatusFilter=${this.webAdminStatusFilter}${excelQueryString}`
        );
        if (
          request.data.success &&
          request.data.message == "ONLINE APPLICATIONS FETCHED SUCCESSFULLY"
        ) {
          this.applicants = request.data.online_applications;
          this.totalApplicants = request.data.total_online_applications;
          window.scrollTo(0, 0);
        } else if (
          request.data.success &&
          request.data.message == "ONLINE APPLICATIONS EXPORTED SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Processing Download",
            "The requested file is being downloaded. Please wait..."
          );
          const downloadLink = request.data.link;

          // download the excel report based on the api endpoint returned.
          const link = document.createElement("a");
          link.href = downloadLink;
          link.click();
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Online Applications",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    handleCommand(command) {
      if (command === "download-all-students") {
        this.downloadAllStudents();
      }
      if (command === "download-active-students") {
        this.downloadActiveStudents();
      }
      if (command === "download-inactive-students") {
        this.downloadInactiveStudents();
      }
    },

    handleActionsSelection(command) {
      const commandEventFired = command.split("#");
      const commandName = commandEventFired[0];
      const websiteOnlineApplicationId = commandEventFired[1];
      if (commandName === "student-information") {
        this.studentInformation(websiteOnlineApplicationId);
      }
      if (commandName === "edit-online-application") {
        this.goToEditOnlineApplication(websiteOnlineApplicationId);
      }
      if (commandName === "register-student") {
        this.registerStudent(websiteOnlineApplicationId);
      }
      if (commandName === "toggle-read-status") {
        this.toggleReadStatus(websiteOnlineApplicationId);
      }
    },

    studentInformation(websiteOnlineApplicationId) {
      this.showStudentInfoDialog = true;
      this.websiteOnlineApplicationId = websiteOnlineApplicationId;
    },

    closeStudentInfoDialog() {
      this.showStudentInfoDialog = false;
    },
    // admit student
    async registerStudent(websiteOnlineApplicationId) {
      await this.$confirm(
        "Are you sure you want to Admit this student. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "success",
        }
      );
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.patch(
          `website/online-web-applications/register-student/${websiteOnlineApplicationId}`
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT REGISTERED SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Registered successfully",
            "The student was successfully registered"
          );
          this.getAllApplications();
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (
            error.response.data.message === "ONLINE APPLICATION IS NOT COMPLETE"
          ) {
            return this.showWarningMessage(
              "Missing Information",
              "The student can not be registered right now. Please fill all the required information and try again"
            );
          } else if (
            error.response.data.message ==
            "ONLINE APPLICATION HAS ALREADY BEEN ADMITTED"
          ) {
            this.showWarningMessage(
              "Already Admitted",
              "This student has already been admitted"
            );
            return this.getAllApplications();
          } else if (
            error.response.data.message == "ONLINE APPLICATION NOT FOUND	"
          ) {
            this.showWarningMessage(
              "Not Found",
              "Unable to find the provided website online application"
            );
            return this.getAllApplications();
          }
        }
        this.showFailedMessage(
          "Unable to Admit Student",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    goToEditOnlineApplication(onlineApplicationId) {
      this.$router.push({
        name: "EditOnlineApplication",
        params: { onlineApplicationId },
      });
    },

    async toggleReadStatus(websiteOnlineApplicationId) {
      let request = await this.httpRequest({
        method: "PATCH",
        url: `website/online-web-applications/toggle-read-status/${websiteOnlineApplicationId}`,
        loadingPropertyName: "loading",
        errorLoadingPropertyName: "loadingError",
        showSuccessMessage: true,
      });

      if (
        request &&
        request.success &&
        request.message == "Online Application read status updated successfully"
      ) {
        this.getAllApplications();
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 9px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

#admissionLetter {
  display: none;
}

#printAdmissionLetter {
  display: none;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.download_btn {
  width: 240px;
}

@media print {
  #admissionLetter {
    display: block;
  }
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .download_btn {
    width: 250px;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }

  .search_by_input {
    width: 100% !important;
  }

  .download_btn {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .search_add_section {
    display: block;
  }

  .search_by_input {
    width: 100% !important;
  }

  .download_btn {
    width: 100% !important;
  }
}
</style>

<style>
.students_dialogs .el-dialog {
  width: 60%;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .students_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .students_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .students_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .students_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
